<template>
    <div class="index-container">
        <CommonHeader />
        <div class="indextop">
            <!-- <img class="bg-banner" :src="require('@/assets/images/index/index-topbg.png')" alt=""> -->
            <div class="indextop-desc">
                <div class="indextop-text">
                    <p class="indextop-title">文泉幼教</p>
                    <p class="indextop-info">   “文泉幼教”数字资源产品，是清华大学出版社有限公司开发，集教师教学、学生学习、考核考试、实训演练于一体的教、学、考、练互动教学产品体系。通过数字教学案例资源库、数字考试系统、实训数字资源库建设，形成学前教育专业完整的教、学、考、练的教学闭环。</p>
  <p class="indextop-info"> “文泉幼教”数字资源产品，内容强化教学设计，整合多样化数字资源，基于信息化技术手段提升教学质量，并且融合出版特色优势，所有数字资源产品均经过清华大学出版社出版，专业性、规范性强。</p>
 <p class="indextop-info"> 同时，清华大学出版社还为教师利用“文泉幼教”数字资源产品进行二次开发提供成果出版服务，实现优质教学资源共建、共享。
</p>
                </div>
                <img class="indextop-pic" :src="require('@/assets/images/index/index-top1.png')" alt="">
            </div>
        </div>
        <div class="main-container">
            <div class="case-part">
                <div class="title-checkmore">
                    <p class="common-title">合作案例</p>
                    <div class="check-more" @click="jumpToCase"><span>查看更多</span><span class="arrow-more"></span></div>
                </div>
                <CaseTable />
            </div>
            <div class="second-part">
                <p class="common-title">面向学前教育专业高等院校师生</p>
                <div class="icons-parts">
                    <div>
                        <img :src="require('@/assets/images/index/icon-1.png')" alt="">
                        <p>在新冠疫情影响下，解决学生无法到园实训或实习内容生疏的问题</p>
                    </div>
                    <div>
                        <img :src="require('@/assets/images/index/icon-2.png')" alt="">
                        <p>解决教师教学中缺少实际案例素材，学生理论学习枯燥的难题</p>
                    </div>
                    <div>
                        <img :src="require('@/assets/images/index/icon-3.png')" alt="">
                        <p>解决理论与实践脱节的难题，推动高校混合式教学模式改造</p>
                    </div>
                    <div>
                        <img :src="require('@/assets/images/index/icon-4.png')" alt="">
                        <p>提高学生实践能力，促进就业水平</p>
                    </div>
                </div>
            </div>
            <div class="third-part" v-if="false">
                <div class="title-checkmore">
                    <p class="common-title">综合实训</p>
                    <div class="check-more" @click="jumpToPractice"><span>查看更多</span><span class="arrow-more"></span></div>
                </div>
                <div class="third-cards">
                    <div class="third-cardinfo">
                        <img :src="require('@/assets/images/index/third-1.png')" alt="">
                        <div><span>学前教育专业综合实训数字资源系统（3-6岁）</span></div>
                        <div class="third-cardinfo-hover">
                            <p  @click="jumpToMenu('1')">学前教育专业综合实训数字资源系统（3-6岁）</p>
                            <a href="javascript:;" @click="jumpToSubMenu1('one')">学前教育一日活动综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu1('two')">学前卫生保育与膳食综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu1('three')">学前教育环境创设综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu1('four')">学前安全应急综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu1('five')">儿童发展心理学综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu1('six')">幼儿教育VR综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu1('seven')">学前教育互动综合实训数字资源系统</a>
                        </div>
                    </div>
                    <div class="third-cardinfo">
                        <img :src="require('@/assets/images/index/third-2.png')" alt="">
                        <div><span>早期教育专业综合实训数字资源系统（0-3岁）</span></div>
                        <div class="third-cardinfo-hover">
                            <p @click="jumpToMenu('2')">早期教育专业综合实训数字资源系统（0-3岁）</p>
                            <a href="javascript:;" @click="jumpToSubMenu2('eight')">幼儿照护保育综合实训数字资源系统</a>
                            <a href="javascript:;" @click="jumpToSubMenu2('nine')">早期教育教学综合实训数字资源系统</a>
                        </div>
                    </div>
                    <div class="third-cardinfo">
                        <img :src="require('@/assets/images/index/third-3.png')" alt="">
                        <div><span>VR/AR虚拟仿真互动综合实训室</span></div>
                        <div class="third-cardinfo-hover" @click="jumpToMenu('3')">
                            <p>VR/AR虚拟仿真互动综合实训室</p>
                            <span>VR全沉浸式头盔、VR教学体验一体机、桌面一体机、智慧纳米黑板、AR互动操作台、创客小组学习环境。</span>
                        </div>
                    </div>
                    <div class="third-cardinfo">
                        <img :src="require('@/assets/images/index/third-4.png')" alt="">
                        <div><span>学前教育理虚实一体化实训基地</span></div>
                        <div class="third-cardinfo-hover" @click="jumpToMenu('4')">
                            <p>学前教育理虚实一体化实训基地</p>
                            <span>VR/AR虚拟仿真实训室、幼儿园模拟实训室、学前卫生保育实训室、学前安全教育实训室、1+X幼儿照护实训室、幼儿园营养膳食实训室、早期教育虚拟仿真实训室、五大领域教学活动实训室、儿童心理与行为观察室等实体环境的搭建，设备、教具的采购。</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="forth-part">
                <img :src="require('@/assets/images/index/forth-1.png')" alt="">
                <div class="forth-rightexam">
                    <p class="common-title">考试题库</p>
                    <span>清华社向高校提供教师资格证考试（幼儿园）平台</span>
                    <span>包括知识点体系、900余道真题与1400余道模拟题、专家解析、视频辅导课程</span>
                    <div class="forth-icons">
                        <div class="forth-iconpart">
                            <div class="forth-lefticon"><img :src="require('@/assets/images/index/icon-edit.png')" alt=""></div>
                            <div class="forth-rightinfo">
                                <p>笔试</p>
                                <span>综合素质、保教知识与能力</span>
                            </div>
                        </div>
                        <div class="forth-iconpart">
                            <div class="forth-lefticon"><img :src="require('@/assets/images/index/icon-smile.png')" alt=""></div>
                            <div class="forth-rightinfo">
                                <p>面试</p>
                                <span>结构化面试题、试讲与答辩题</span>
                            </div>
                        </div>
                    </div>
                    <button class="learn-more" @click="jumpToExams">了解更多</button>
                </div>
            </div>
            <div class="fifth-part">
                <div class='fifth-left'>
                    <p class="common-title">教学资源库</p>
                    <span>清华社向高校提供的教学资源库基于健康、语言、社会、科学、艺术五大领域，按照课程知识点打造的主题活动组织案例、日常教学案例、突发事件处理案例等，资源包括微课、课件、教案、试卷、教学演示视频、专家点评视频等形式。</span>
                    <button class="learn-more" @click="jumpToSourceStore">了解更多</button>
                </div>
                <img :src="require('@/assets/images/index/fifth.png')" alt="">
            </div>
            <div class="sixth-part">
                <div class="title-checkmore">
                    <p class="common-title">合作院校</p>
                    <div class="check-more" @click="jumpToCoop"><span>查看更多</span><span class="arrow-more"></span></div>
                </div>
                <div class="sixth-pics">
                    <div><img :src="require('@/assets/images/index/six-11.png')" alt=""><span>中科院心理研究所</span></div>
                    <div><img :src="require('@/assets/images/index/six-1.png')" alt=""><span>首都师范大学</span></div>
                    <div><img :src="require('@/assets/images/index/wnsf.png')" alt=""><span>渭南师范学院</span></div>
                    <div><img :src="require('@/assets/images/index/six-7.png')" alt=""><span>陕西学前师范学院</span></div>
                    <div><img :src="require('@/assets/images/index/xawl.png')" alt=""><span>西安文理学院</span></div>
                    <div><img :src="require('@/assets/images/index/six-5.png')" alt=""><span>山东女子学院</span></div>
                    <div><img :src="require('@/assets/images/index/sdgl.png')" alt=""><span>山东管理学院</span></div>
                    <div><img :src="require('@/assets/images/index/six-3.png')" alt=""><span>石家庄学院</span></div>
                    <div><img :src="require('@/assets/images/index/hs.png')" alt=""><span>衡水学院</span></div>
                    <div><img :src="require('@/assets/images/index/six-14.png')" alt=""><span>咸阳职业技术学院</span></div>
                    <!-- <div><img :src="require('@/assets/images/index/six-12.png')" alt=""><span>石家庄幼儿师范高等专科学院</span></div> -->
                    <!-- <div><img :src="require('@/assets/images/index/six-9.png')" alt=""><span>福建幼儿师范高等专科学校</span></div> -->
                </div>
            </div>
        </div>
        <CommonFooter />
        <CommonTop />
    </div>
</template>
<script>
import CommonFooter from '@/components/common/CommonFooter.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import CommonTop from '@/components/common/CommonTop.vue'
import CaseTable from '@/components/CaseTable.vue'
export default {
    components: { CommonHeader, CommonFooter, CommonTop, CaseTable },
    props: {},
    data() {
        return {
        }
    },
    created() {},
    mounted() {},
    methods: {
        jumpToCase() {
            this.$router.push({name: 'case'})
            document.documentElement.scrollTop = 0
        },
        jumpToCoop() {
            this.$router.push({name: 'cooperate'})
            document.documentElement.scrollTop = 0
        },
        jumpToExams() {
            this.$router.push({name: 'exams'})
            document.documentElement.scrollTop = 0
        },
        jumpToSourceStore() {
            this.$router.push({name: 'sourceStore'})
            document.documentElement.scrollTop = 0
        },
        jumpToPractice() {
            this.$router.push({name: 'practice'})
            document.documentElement.scrollTop = 0
        },
        jumpToMenu(tab) {
            this.$router.push({name: 'practice', query: {tab}})
            document.documentElement.scrollTop = 280
        },
        jumpToSubMenu1(tab) {
            this.$router.push({name: 'practice', query: {tab: '1', submenu: tab}})
            document.documentElement.scrollTop = 280
        },
        jumpToSubMenu2(tab) {
            this.$router.push({name: 'practice', query: {tab: '2', submenu: tab}})
            document.documentElement.scrollTop = 280
        }
    }
}
</script>
<style lang='scss' scoped>
$mainWidth: 1180px;
.indextop {
    width: 100%;
    height: 562px;
    position: relative;
    padding-top: 60px;
    background: url('./../../assets/images/index/index-topbg.png') no-repeat center;
    background-size: cover;
    box-sizing: border-box;

    .bg-banner {
        width: 100%;
        height: auto;
    }
    .indextop-desc {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: $mainWidth;
        .indextop-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .indextop-title {
                font-size: 56px;
                font-weight: 500;
                color: #000000;
                line-height: 78px;
                margin-bottom: 10px;
            }
            .indextop-info {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 26px;
                width: 672px;
                text-indent: 2em;
            }
        }
        .indextop-pic {
            width: 416px;
            height: auto;
            margin-left: 92px;
        }
    }
}
.main-container {
    width: $mainWidth;
    margin: auto;
    .case-part {
        margin-top: 42px;
    }
    .second-part {
        padding: 70px 0 90px;
        &>p {
            text-align: center;
            margin-bottom: 60px;
        }
        .icons-parts {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            &>div {
                font-size: 14px;
                font-weight: 400;
                color: #0768AB;
                line-height: 24px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                &>img {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 35px;
                }
                &>p {
                    width: 170px;
                }
            }
        }
    }
    .third-part {
        background: #F6F9FD;
        padding: 50px 25px;
        box-sizing: border-box;
        width: 100%;
        &>p {
            text-align: center;
        }
        .third-cards {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 40px;
            .third-cardinfo {
                width: 270px;
                height: 290px;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                position: relative;
                &>img {
                    width: 100%;
                    height: auto;
                }
                &>div {
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                    padding: 35px 14px 0;
                    box-sizing: border-box;
                }
                .third-cardinfo-hover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(27deg, #577EF1 0%, rgba(52, 200, 162, 0.83) 100%);
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    padding: 25px 20px 0;
                    box-sizing: border-box;
                    text-align: left;
                    color: #fff;
                    font-size: 12px;
                    display: none;
                    transition: all .3s;
                    &>p {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
                        cursor: pointer;
                        border-bottom: 1px solid transparent;
                        &:hover {
                            border-bottom: 1px solid #fff;
                        }
                    }
                    &>a {
                        display: block;
                        line-height: 28px;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
                        cursor: pointer;
                        color: #fff;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &>span {
                        display: block;
                        line-height: 28px;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
                        color: #fff;
                        padding-top: 24px;
                    }
                }
                &:hover {
                    .third-cardinfo-hover {
                        display: block;
                    }
                    // background: linear-gradient(27deg, #577EF1 0%, rgba(52, 200, 162, 0.83) 100%);
                }
            }
        }
    }
    .forth-part {
        padding: 82px 0 95px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &>img {
            width: 540px;
            margin-right: 60px;
        }
        .forth-rightexam {
            &>p {
                margin-bottom: 20px;
            }
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 26px;
                margin-bottom: 4px;
                display: block;
            }
            .forth-icons {
                margin-top: 22px;
                margin-bottom: 33px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                .forth-iconpart {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    .forth-lefticon {
                        width: 72px;
                        height: 72px;
                        background: linear-gradient(0deg, #FF7464 0%, #FFC690 100%);
                        border-radius: 8px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin-right: 14px;
                        &>img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                    .forth-rightinfo {
                        &>p {
                            font-size: 16px;
                            font-weight: 600;
                            color: #333333;
                            line-height: 22px;
                            margin-bottom: 4px;
                        }
                        &>span {
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            line-height: 22px;
                            width: 110px;
                            display: block;
                        }
                    }
                    &:nth-child(2) {
                        margin-left: 55px;
                        .forth-lefticon {
                            background: linear-gradient(180deg, #A4F5C2 0%, #7FD5DD 100%);
                        }
                    }
                }
            }
        }
    }
    .fifth-part {
        background: #F6F9FD;
        padding: 70px 190px 54px 127px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .fifth-left {
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 26px;
                display: block;
                padding: 14px 0;
                width: 440px;
            }
        }
        &>img {
            width: 286px;
        }
    }
    .sixth-part {
        padding: 72px 0 60px;
        box-sizing: border-box;
        .sixth-pics {
            margin-top: 42px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            &>div {
                width: 236px;
                position: relative;
                &>img {
                    width: 100%;
                }
                &>span {
                    position: absolute;
                    bottom: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    text-align: center;
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
</style>