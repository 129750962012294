<template>
    <div class="case-table">
      <div class="case-table-header">
        <div>合作院校</div>
        <div>合作内容</div>
      </div>
      <div class="case-table-content">
        <div class="case-table-content-group">本科及以上</div>
        <div class="case-table-content-items" v-for="item in listByBenke" :key="item.index">
          <div class="case-table-content-items-line">
            <div>{{item.index}}</div>
            <div>{{item.name}}</div>
            <div>{{item.desc}}</div>
          </div>
          <div class="hover-items">
              <div class="hover-items-header">
                  <img class="hover-items-header-imgs" :src="item.banner" alt="">
                  <div class="hover-items-header-infos">
                      <p class="hover-items-header-infos-school">{{item.name}}</p>
                      <p class="hover-items-header-infos-project">{{item.desc}}</p>
                  </div>
              </div>
              <img class="hover-items-img" src="@/assets/images/case/屈老师.jpeg" alt="">
              <p class="hover-items-tips">添加微信了解更多</p>
          </div>
        </div>
        <div class="case-table-content-group">高职高专</div>
        <div class="case-table-content-items" v-for="item in listByGaozhi" :key="item.index">
          <div class="case-table-content-items-line">
            <div>{{item.index}}</div>
            <div>{{item.name}}</div>
            <div>{{item.desc}}</div>
          </div>
          <div class="hover-items hover-items-top">
              <div class="hover-items-header">
                  <img class="hover-items-header-imgs" :src="item.banner" alt="">
                  <div class="hover-items-header-infos">
                      <p class="hover-items-header-infos-school">{{item.name}}</p>
                      <p class="hover-items-header-infos-project">{{item.desc}}</p>
                  </div>
              </div>
              <img class="hover-items-img" src="@/assets/images/case/屈老师.jpeg" alt="">
              <p class="hover-items-tips">添加微信了解更多</p>
          </div>
        </div>
        <div class="case-table-content-group">中职</div>
        <div class="case-table-content-items" v-for="item in listByZhongzhi" :key="item.index">
          <div class="case-table-content-items-line">
            <div>{{item.index}}</div>
            <div>{{item.name}}</div>
            <div>{{item.desc}}</div>
          </div>
          <div class="hover-items hover-items-top">
              <div class="hover-items-header">
                  <img class="hover-items-header-imgs" :src="item.banner" alt="">
                  <div class="hover-items-header-infos">
                      <p class="hover-items-header-infos-school">{{item.name}}</p>
                      <p class="hover-items-header-infos-project">{{item.desc}}</p>
                  </div>
              </div>
              <img class="hover-items-img" src="@/assets/images/case/屈老师.jpeg" alt="">
              <p class="hover-items-tips">添加微信了解更多</p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            listByBenke: [
              {
                index: 1,
                name: '中科院心理学研究所',
                desc: '儿童发展心理学综合实训数字资源系统',
                banner: require('@/assets/images/case/1.png'),
              }, {
                index: 2,
                name: '首都师范大学',
                desc: '学前教育学院虚拟仿真实训资源',
                banner: require('@/assets/images/case/17.png'),
              }, {
                index: 3,
                name: '渭南师范学院',
                desc: '学前教育五大领域教学案例库',
                banner: require('@/assets/images/case/21.png'),
              }, {
                index: 4,
                name: '陕西学前师范学院',
                desc: '幼儿教师资格证考试系统',
                banner: require('@/assets/images/case/2.png'),
              }, {
                index: 5,
                name: '西安文理学院',
                desc: '学前教育专业核心课程建构与教学改革',
                banner: require('@/assets/images/case/8.png'),
              }, {
                index: 6,
                name: '山东女子学院',
                desc: '0-3岁婴幼儿教养类专业实训系统',
                banner: require('@/assets/images/case/14.png'),
              }, {
                index: 7,
                name: '山东管理学院',
                desc: '学前教育远程师资培训系统',
                banner: require('@/assets/images/case/3.png'),
              }, {
                index: 8,
                name: '石家庄学院',
                desc: '学前教育实训数字资源库系统',
                banner: require('@/assets/images/case/9.png'),
              }, {
                index: 9,
                name: '衡水学院',
                desc: '学前教育优质幼儿教师培训',
                banner: require('@/assets/images/case/15.png'),
              }
            ],
            listByGaozhi: [
              {
                index: 1,
                name: '咸阳职业技术学院',
                desc: '学前教育数字课程出版——数字教材《学前教育学》通过“十四五”国家规...',
                banner: require('@/assets/images/case/18.png'),
              }, {
                index: 2,
                name: '福建幼儿师范高等专科学校',
                desc: '幼儿园环境创设实训系统',
                banner: require('@/assets/images/case/12.png'),
              }, {
                index: 3,
                name: '四川幼儿师范高等专科学校',
                desc: '幼儿教师资格证考试系统',
                banner: require('@/assets/images/case/16.png'),
              }, {
                index: 4,
                name: '石家庄幼儿师范高等专科学校',
                desc: '学前教育虚拟仿真实训数字资源系统',
                banner: require('@/assets/images/case/4.png'),
              }, {
                index: 5,
                name: '阜阳幼儿师范高等专科学校',
                desc: '学前教育专业新形态教材出版',
                banner: require('@/assets/images/case/10.png'),
              }, {
                index: 6,
                name: '吕梁师范高等专科学校',
                desc: '学前教育“理-虚-实”一体化实训室（一日活动、卫生保育）',
                banner: require('@/assets/images/case/20.png'),
              }, {
                index: 7,
                name: '鹤壁职业技术学院',
                desc: '学前卫生保育与膳食综合实训数字资源系统',
                banner: require('@/assets/images/case/5.png'),
              }
            ],
            listByZhongzhi: [
              {
                index: 1,
                name: '娄底幼儿师范学校',
                desc: '学前教育“理-虚-实”一体化实训室（虚拟幼儿园、环创、心理与行为）',
                banner: require('@/assets/images/case/11.png'),
              }
            ]
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
$height: 36px;
@mixin flex-center() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.case-table {
  width: 960px;
  margin: 20px auto 0;
  &-header {
    @include flex-center();
    height: $height;
    &>div {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      width: 50%;
      background: linear-gradient(46deg, #3480D8 0%, #339DE9 100%);
      border: 2px solid #FFFFFF;
      height: 100%;
      @include flex-center();
      box-sizing: border-box;
    }
  }
  &-content {
    height: 330px;
    overflow: auto;
    &-group {
      background: #EFF4F8;
      border: 2px solid #FFFFFF;
      @include flex-center();
      height: $height;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      box-sizing: border-box;
    }
    &-items {
      @include flex-center();
      height: $height;
      background: #EFF4F8;
      cursor: pointer;
      position: relative;
      &-line {
        width: 100%;
        @include flex-center();
        &>div {
          border: 2px solid #FFFFFF;
          height: $height;
          box-sizing: border-box;
          @include flex-center();
          &:first-child {
            width: 70px;
            font-size: 14px;
            color: #9FA8AF;
          }
          &:nth-child(2) {
            width: calc( 50% - 70px );
            font-size: 14px;
            color: #26343E;
          }
          &:last-child {
            width: 50%;
            font-size: 14px;
            color: #0064AF;
          }
        }
      }
      &:hover {
        background-color: #DFF0FD;
        .hover-items {
          display: flex;
        }
      }
      .hover-items {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        position: absolute;
        top: 28px;
        left: 78px;
        width: 361px;
        // height: 198px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        z-index: 10;
        padding: 14px;
        &-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start; 
            &-imgs {
                height: 40px;
                margin-right: 12px;
            }
            &-infos {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start; 
                &-school {
                    font-size: 14px;
                    color: #666666;
                    line-height: 20px;
                }
                &-project {
                    font-size: 16px;
                    font-weight: 600;
                    color: #0768AB;
                    line-height: 22px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
        &-img {
            width: 102px;
            height: 102px;
            margin: 5px auto;
        }
        &-tips {
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            text-align: center;
            width: 100%;
        }
      }
      .hover-items-top {
        bottom: 28px;
        top: unset;
      }
    }
  }
}
</style>